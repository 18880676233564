import * as React from "react";

import { Error401Page } from "tabler-react";

type Props = {};

function Error401(props: Props) {
  return <Error401Page />;
}

export default Error401;
