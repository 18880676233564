import {
  IconBrandBitbucket,
  IconBrandDribbble,
  IconBrandFacebook,
  IconBrandFlickr,
  IconBrandGithub,
  IconBrandGoogle,
  IconBrandInstagram,
  IconBrandPinterest,
  IconBrandTabler,
  IconBrandTwitter,
  IconBrandVimeo,
  IconBrandVk,
  IconBrandYoutube,
  IconRss,
} from "@tabler/icons";

import { Grid, Card, Button, standardColors, extraColors } from "tabler-react";

import SiteWrapper from "../SiteWrapper";

const social: [any, any][] = [
  ["facebook", <IconBrandFacebook />],
  ["twitter", <IconBrandTwitter />],
  ["google", <IconBrandGoogle />],
  ["youtube", <IconBrandYoutube />],
  ["vimeo", <IconBrandVimeo />],
  ["dribble", <IconBrandDribbble />],
  ["github", <IconBrandGithub />],
  ["instagram", <IconBrandInstagram />],
  ["pinterest", <IconBrandPinterest />],
  ["vk", <IconBrandVk />],
  ["rss", <IconRss />],
  ["flickr", <IconBrandFlickr />],
  ["bitbucket", <IconBrandBitbucket />],
  ["tabler", <IconBrandTabler />],
];

const states = ["normal", "active", "disabled"];

const types = ["standard", "outline", "ghost", "square", "pill"];

function ButtonsPage() {
  return (
    <SiteWrapper title="Buttons">
      <Grid.Row cards>
        <Grid.Col col={12}>
          {types.map(t => {
            return (
              <Card
                header={
                  <Card.Title>{`${t[0].toUpperCase()}${t.substring(
                    1
                  )} Buttons`}</Card.Title>
                }
                mb={4}
                key={t}
              >
                <Card.Body>
                  {states.map(s => {
                    if (
                      !["standard", "outline", "ghost"].includes(t) &&
                      s !== "normal"
                    ) {
                      return null;
                    }
                    return (
                      <Grid.Row
                        g={2}
                        gMd={2}
                        m={2}
                        alignItems="center"
                        mb={3}
                        key={s}
                      >
                        <Grid.Col col={12} mb={3} xl={2}>
                          {`${s[0].toUpperCase()}${s.substring(1)} Buttons`}
                        </Grid.Col>
                        {standardColors.map(b => {
                          return (
                            <Grid.Col key={b} col="xl" sm={4} md={2} mb={3}>
                              <Button
                                color={b}
                                w={100}
                                active={s === "active"}
                                disabled={s === "disabled"}
                                variant={
                                  t === "outline" || t === "ghost"
                                    ? t
                                    : undefined
                                }
                                radius={
                                  t === "square" || t === "pill" ? t : undefined
                                }
                              >{`${b[0].toUpperCase()}${b.substring(
                                1
                              )}`}</Button>
                            </Grid.Col>
                          );
                        })}
                      </Grid.Row>
                    );
                  })}
                </Card.Body>
              </Card>
            );
          })}
          <Card header={<Card.Title>Extra Colors</Card.Title>} mb={4}>
            <Card.Body>
              <Grid.Row g={2} alignItems="center">
                {extraColors.map(b => {
                  return (
                    <Grid.Col key={b} xl="auto" col={6} sm={4} md={2} mb={3}>
                      <Button
                        color={b}
                        w={100}
                      >{`${b[0].toUpperCase()}${b.substring(1)}`}</Button>
                    </Grid.Col>
                  );
                })}
              </Grid.Row>
            </Card.Body>
          </Card>
          <Card header={<Card.Title>Icon buttons</Card.Title>} mb={4}>
            <Card.Body>
              <Grid.Row g={2} alignItems="center">
                {social.map(b => {
                  return (
                    <Grid.Col key={b[0]} xl="auto" col={6} sm={4} md={2} mb={3}>
                      <Button color={b[0]} w={100} icon={b[1]} />
                    </Grid.Col>
                  );
                })}
              </Grid.Row>
            </Card.Body>
          </Card>
          <Card header={<Card.Title>Social Colors</Card.Title>} mb={4}>
            <Card.Body>
              <Grid.Row g={2} alignItems="center">
                {social.map(b => {
                  return (
                    <Grid.Col key={b[0]} xl="auto" col={6} sm={4} md={2} mb={3}>
                      <Button
                        color={b[0]}
                        w={100}
                        icon={b[1]}
                      >{`${b[0][0].toUpperCase()}${b[0].substring(1)}`}</Button>
                    </Grid.Col>
                  );
                })}
              </Grid.Row>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </SiteWrapper>
  );
}

export default ButtonsPage;
