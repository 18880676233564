import { Grid, Card, Button, Dimmer } from "tabler-react";

import SiteWrapper from "../SiteWrapper";

const LoremBody = (
  <p>
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam deleniti
    fugit incidunt, iste, itaque minima neque pariatur perferendis sed suscipit
    velit vitae voluptatem.
  </p>
);

export const cards = [
  {
    TextInBody: {
      children: (
        <Card.Body>
          <p>This is some text within a card</p>
        </Card.Body>
      ),
    },
    Borderless: {
      borderless: true,
      body: <p>This is a borderless card</p>,
    },
    BottomImage: {
      title: "Card with bottom image",
      imageBottom: "./static/photos/56614e12b2a7bd68.jpg",
      body: LoremBody,
    },
    ActiveState: {
      active: "active",
      body: <p>This is a card with active state</p>,
    },
    InactiveState: {
      active: "inactive",
      body: <p>This is a card with inactive state</p>,
    },
    Footer: {
      body: LoremBody,
      footer: (
        <Grid.Row alignItems="center">
          <Grid.Col col="auto">
            <a href="#">More information</a>
          </Grid.Col>
          <Grid.Col col="auto" ms="auto">
            <label className="form-check form-switch m-0">
              <input
                className="form-check-input position-static"
                type="checkbox"
              />
            </label>
          </Grid.Col>
        </Grid.Row>
      ),
    },
  },
  {
    LeftImage: {
      title: "Card with left side image",
      imageLeft: "./static/photos/2854fd67ddbd6217.jpg",
      body: LoremBody,
    },
    RightImage: {
      title: "Card with right side image",
      imageRight: "./static/photos/de6d0fd1feebb6a2.jpg",
      body: LoremBody,
    },
    TopImage: {
      title: "Card with top image",
      imageTop: "./static/photos/9f36332564ca271d.jpg",
      body: LoremBody,
    },
    WithButtonLink: {
      title: "Card with button link",
      body: (
        <>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam
            deleniti fugit incidunt, iste, itaque minima neque pariatur
            perferendis sed suscipit velit vitae voluptatem.
          </p>
          <Button color="primary">Go somewhere</Button>
        </>
      ),
    },
    TopStatus: {
      title: "Card with top status",
      statusColor: "danger",
      statusPosition: "top",
      body: LoremBody,
    },
    BottomStatus: {
      title: "Card with bottom status",
      statusColor: "success",
      statusPosition: "bottom",
      body: LoremBody,
    },
    SideStatus: {
      title: "Card with side status",
      statusColor: "primary",
      statusPosition: "start",
      body: LoremBody,
    },
    Stacked: {
      title: "Stacked card",
      stacked: true,
      body: LoremBody,
    },
  },
] as any;

function CardsDesignPage() {
  return (
    <SiteWrapper title="Cards">
      <Grid.Row cards>
        <Grid.Col md={6} xl={4}>
          <Grid.Row cards>
            {Object.keys(cards[0]).map((key, index) => {
              const card = cards[0][key];
              return (
                <Grid.Col col={12} key={index}>
                  <Card {...card} />
                </Grid.Col>
              );
            })}
          </Grid.Row>
        </Grid.Col>
        <Grid.Col md={6} xl={8}>
          <Grid.Row cards>
            {Object.keys(cards[1]).map((key, index) => {
              const card = cards[1][key];
              return (
                <Grid.Col col={12} key={index}>
                  <Card {...card} />
                </Grid.Col>
              );
            })}
            <Grid.Col col={12}>
              <Card.Group>
                <Card
                  body={<p>This is first card</p>}
                  active="inactive"
                  textAlign="center"
                  as="a"
                  href="#"
                />
                <Card
                  body={<p>This is second card</p>}
                  active="active"
                  textAlign="center"
                  as="a"
                  href="#"
                />
                <Card
                  body={<p>This is third card</p>}
                  textAlign="center"
                  as="a"
                  href="#"
                />
              </Card.Group>
            </Grid.Col>
            <Grid.Col lg={6} xl={4}>
              <Card header title="Card with loader" isClosable isCollapsible>
                <Card.Body>
                  <Dimmer active loader>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Aperiam deleniti fugit incidunt, iste, itaque minima neque
                    pariatur perferendis sed suscipit velit vitae voluptatem. A
                    consequuntur, deserunt eaque error nulla temporibus!
                  </Dimmer>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </SiteWrapper>
  );
}

export default CardsDesignPage;
