import * as React from "react";

import { Error503Page } from "tabler-react";

type Props = {};

function Error503(props: Props) {
  return <Error503Page />;
}

export default Error503;
