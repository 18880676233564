import * as React from "react";

import { Error500Page } from "tabler-react";

type Props = {};

function Error500(props: Props) {
  return <Error500Page />;
}

export default Error500;
