import * as React from "react";

import { ForgotPasswordPage as TablerForgotPasswordPage } from "tabler-react";

type Props = {};

function ForgotPasswordPage(props: Props) {
  return <TablerForgotPasswordPage />;
}

export default ForgotPasswordPage;
