import { NavLink as ReactRouterNavLink } from "react-router-dom";
import {
  IconHome,
  IconBox,
  IconLayoutGrid,
  IconCheckbox,
  IconStar,
  IconNote,
} from "@tabler/icons";

import { Site } from "tabler-react";

const navBarItems = [
  {
    value: "Home",
    to: "/",
    icon: <IconHome />,
    linkAs: ReactRouterNavLink,
    linkProps: { exact: true },
  },
  {
    value: "Interface",
    icon: <IconBox />,
    href: "#interface",
    subItemsObjects: [
      {
        value: "Buttons",
        to: "/buttons",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Cards Design",
        to: "/cards",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Charts",
        to: "/charts",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Maps",
        to: "/maps",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Icons",
        to: "/icons",
        linkAs: ReactRouterNavLink,
      },
    ],
  },
  {
    value: "Form elements",
    to: "/form-elements",
    icon: <IconCheckbox />,
    linkAs: ReactRouterNavLink,
  },
  {
    value: "Extras",
    icon: <IconStar />,
    href: "#pages",
    subItemsObjects: [
      {
        value: "Profile",
        to: "/profile",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Login",
        to: "/login",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Register",
        to: "/register",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Forgot password",
        to: "/forgot-password",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "400 error",
        to: "/400",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "401 error",
        to: "/401",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "403 error",
        to: "/403",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "404 error",
        to: "/404",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "500 error",
        to: "/500",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "503 error",
        to: "/503",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Email",
        to: "/email",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Empty page",
        to: "/empty-page",
        linkAs: ReactRouterNavLink,
      },
      { value: "RTL", to: "/rtl", linkAs: ReactRouterNavLink },
      {
        value: "Gallery",
        to: "/gallery",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Pricing Cards",
        to: "/pricing-cards",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Store",
        to: "/store",
        linkAs: ReactRouterNavLink,
      },
      {
        value: "Blog",
        to: "/blog",
        linkAs: ReactRouterNavLink,
      },
    ],
  },
  {
    value: "Layout",
    icon: <IconLayoutGrid />,
    disabled: true,
  },
  {
    icon: <IconNote />,
    value: "Documentation",
    disabled: true,
    to:
      process.env.NODE_ENV === "production"
        ? "https://tabler.github.io/tabler-react/documentation"
        : "/documentation",
  },
];

const SiteNav = function () {
  return <Site.Nav itemsObjects={navBarItems} />;
};

export default SiteNav;
