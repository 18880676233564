// @flow

import * as React from "react";

import { Error400Page } from "tabler-react";

type Props = {};

function Error400(props: Props) {
  return <Error400Page />;
}

export default Error400;
