import React from "react";

import { Alert, Site } from "tabler-react";
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import SiteNav from "./SiteNav";
import { SiteWrapperProps } from "components/Site/SiteWrapper";

interface Props extends SiteWrapperProps {
  children: React.ReactNode;
}

const SiteWrapper = function ({ children, ...rest }: Props) {
  return (
    <Site.Wrapper
      header={<SiteHeader />}
      nav={<SiteNav />}
      footer={<SiteFooter />}
      {...rest}
    >
      <Alert
        type="danger"
        title="IMPORTANT - Tabler v2 Alpha"
        hasExtraSpace
        message="This site currently hosts the work in progress on the next version of tabler-react. It's still an alpha so expecting breakages!"
      />
      {children}
    </Site.Wrapper>
  );
};

export default SiteWrapper;
